/* Typography */
@font-face {
    font-family: Subway-Black;
    src: url('Subway-Black.ttf');
}
@font-face {
    font-family: Timeless;
    src: url('Timeless.ttf');
}
body{
    font-family: 'Timeless';
    margin: 1rem;
    background: var(--lightest);
    font-size: 18px;
}
h1, h2, h3, h4{
    font-family: 'Subway-Black';
}
/* Color Vars */
:root{
    --bg-dark: #313842;
    --bg-light: #D39872;
    --lightest: #F7E6D4;
}
/* Lists */
ul{
    list-style: none;
}
a{
    text-decoration: none;
}