.container{
    display: flex;
    flex-direction: column;
    background-color: #313842;
    margin-bottom: 1rem;
    padding-top: 3rem;
    background-image: url(../assets/detroit-header.gif);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    text-align: center;
}
.container ul{
    list-style: none;
}
.container h1{
    color: var(--lightest)
}
.container h2{
    font-family: 'Timeless';
    color: var(--bg-light);
    padding-left: 3rem;
    padding-right: 3rem;
}
.btn{
    margin-bottom: 3rem;
    background-color: var(--bg-light);
    border: none;
    border-radius: 3rem;
    width: 7rem;
    color: var(--bg-dark);
    height: 2rem;
    font-family: 'Timeless';
    font-size: 18px;
}
.btn a{
    color: var(--bg-dark);
}
.ul li{
    color: var(--bg-light);
}
.ul li a{
    color: var(--bg-light);
}
.ul li:first-of-type{
    float: left;
}
.ul li:last-of-type{
    float: right;
    margin-right: 3em;
}