.container{
    background-color: var(--bg-dark);
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}
.container h1{
    color: var(--lightest);
    padding-top: 3rem;
}
.container p{
    color: var(--lightest);
}
.projContainer{
    display: flex;
    flex-direction: row;
}
.project{
    background-color: #ffffff49;
    margin-bottom: 1rem;
    height: 30rem;
}
.project:hover{
    border: 1px solid var(--lightest);
}
.project h2{
    color: var(--lightest);
}
.project p{
    color: var(--lightest);
    padding-bottom: 1rem;
}
.left{
    display: flex; 
    flex-direction: column; 
    width: 50%; 
    margin-right: 1rem;
    margin-left: 2rem;
}
.left p{
    padding: 1rem;
}
.right{
    display: flex; 
    flex-direction: column;  
    width: 50%;
    margin-right: 2rem;
}
.right p{
    padding: 1rem;
}
.projContainer img{
    height: 20rem;
    width: 30rem;
    padding: 1rem;
}
@media only screen and (max-width: 2580px) {
    .left{
        width: 50%;
    }
    .right{
        width: 50%;
    }
    .projContainer img{
        height: 20rem;
        width: 30rem;
    }
    .project{
        height: 35rem;
    }
  }
@media only screen and (max-width: 1550px) {
    .left{
        width: 50%;
    }
    .right{
        width: 50%;
    }
    .projContainer img{
        height: 10rem;
        width: 20rem;
    }
    .project{
        height: 30rem;
    }
  }
@media only screen and (max-width: 950px) {
    .projContainer{
        display: block;
    }
    .project{
        height: max-content;
    }
    .left{
        width: 100%;
        margin-left: 0rem;
    }
    .right{
        width: 100%;
    }
    .projContainer img{
        width: 28rem;
        height: 20rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .projContainer img{
        width: 18rem;
        height: 10rem;
    }
  }