.container{
    display: flex;
}
.bioContainer{
    background-color: var(--bg-light);
    display: flex;
    position: relative;
}
.bio{
    display: flex;
    flex-direction: column;
    text-align: left;
    align-self: center;
    position: relative;
    z-index: 1;
    background-color: var(--bg-light);
    margin-right: 1rem;
    width: 50%;
}
.bio h1, p{
    color: var(--bg-dark);
}
.bio h1{
    padding-left: 3rem;
    padding-right: 3rem;
    padding-top: 1rem;
}
.bio p{
    padding-left: 3rem;
    padding-right: 3rem;
    padding-bottom: 3rem;
}
.lists{
    color: var(--bg-dark);
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-bottom: 3rem;
}
.left{
    width: 50%;
    padding-left: 3rem;
    text-align: left;
}
.right{
    width: 50%;
    text-align: left;
}
.right h4{
    padding-left: 2rem;
}
.imgContainer{
    background-image: url(/src/components/assets/profile.jpg) center;
    background-size: cover;
    margin-left: 1rem;
    width: 50%;
}
.img{
    height: 100%;
    width: 100%;
    object-fit: cover;
}
@media only screen and (max-width: 2580px) {
    .bioContainer{
        width: 40%;
    }
    .bio{
        width: 100%;
    }
    .imgContainer{
        width: 60%;
    }
  }
@media only screen and (max-width: 1280px) {
    .bioContainer{
        width: 60%;
    }
    .bio{
        width: 100%;
    }
    .imgContainer{
        width: 40%;
    }
  }
  @media only screen and (max-width: 840px) {
    .bioContainer{
        width: 80%;
    }
    .bio{
        width: 100%;
    }
    .imgContainer{
        width: 20%;
    }

  }
@media only screen and (max-width: 640px) {
    .container{
        display: block;
    }
    .bioContainer{
        width: 100%;
        margin-bottom: 1rem;
    }
    .imgContainer{
        width: 100%;
        margin-left: 0;
        margin-top: 1rem;
    }
    .lists{
        width: 100%;
    }
    .left{
        width: 60%;
        padding-left: 1rem;
    }
    .right{
        width: 60%;
        padding-right: 1rem;
    }
  }