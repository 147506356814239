.container{
    background-color: var(--bg-dark);
    display: flex;
    flex-direction: column;
}
.container li{
    color: var(--bg-light);
}
.img{
    width: 100%;
    height: 100%;
}
.city{
    height: 10rem;
}
.list li{
    list-style: none;
    float: right;
    padding-right: 3rem;
}