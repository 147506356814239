.container{
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
}
.social ul{
    list-style: none;
}
.social{
    width: 50%;
    background-color: var(--bg-light);
    margin-right: 1rem;
    text-align: left;
}
.social h1{
    color: var(--bg-dark);
    padding-right: 3rem;
    padding-left: 3rem;
    padding-top: 1rem;
}
.social li{
    color: var(--bg-dark);
    padding-bottom: 1rem;
}
.social li a{
    color: var(--bg-dark);
}
.icons{
    height: 30px;
    width: 30px;
    padding-right: 1rem;
}
.why{
    width: 50%;
    background-color: var(--bg-dark);
    text-align: left;
}
.why h1{
    color: var(--lightest);
    padding-top: 1rem;
    padding-right: 3rem;
    padding-left: 3rem;
}
.why p{
    padding-left: 3rem;
    padding-right: 3rem;
    color: var(--lightest);
    text-align: left;
}
.why a{
    color: var(--bg-light);
    font-weight: bold;
}
.why p:last-of-type{
    padding-bottom: 1rem;
}
@media only screen and (max-width: 640px) {
    .container{
        display: block;
    }
    .social{
        width: 100%;
    }
    .why{
        width: 100%;
    }
  }